<template>
  <div>
    <div class="my-3">
      <water-quality-chart :type="type"/>
    </div>
  </div>
</template>

<script>
import WaterQualityChart from "@/components/Farm/Overview/WaterQuality/WaterQualityChart";

export default {
  name: "WaterQualityOverview",
  components: {WaterQualityChart},
  props: {
    type: String,
  },
}
</script>
