<template>
  <div class="is-relative">
    <div class="my-3 btn-tabs" v-if="type === 'general'">
      <b-button :type="field === 'ph' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('ph')">pH</b-button>
      <b-button :type="field === 'do' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('do')">DO</b-button>
      <b-button :type="field === 'temperature' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('temperature')">{{ $t('wq.temperature') }}</b-button>
      <b-button :type="field === 'turbidity' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('turbidity')">{{ $t('wq.turbidity') }}</b-button>
      <b-button :type="field === 'salinity' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('salinity')">{{ $t('wq.salinity') }}</b-button>
      <b-button :type="field === 'water_height' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('water_height')">{{ $t('wq.wh') }}</b-button>
      <b-button :type="field === 'oxygen' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('oxygen')">{{ $t('wq.oxygen') }}</b-button>
      <b-button :type="field === 'orp' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('orp')">ORP</b-button>
      <b-button :type="field === 'color' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('color')">{{ $t('wq.color') }}</b-button>
      <b-button :type="field === 'weather' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('weather')">{{ $t('wq.weather') }}</b-button>
    </div>
    <div class="my-3 btn-tabs" v-else-if="type === 'chemical'">
      <b-button :type="field === 'no2' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('no2')">NO2</b-button>
      <b-button :type="field === 'no3' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('no3')">NO3</b-button>
      <b-button :type="field === 'po4' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('po4')">PO4</b-button>
      <b-button :type="field === 'tom' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('tom')">TOM</b-button>
      <b-button :type="field === 'nh3' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('nh3')">NH3</b-button>
      <b-button :type="field === 'nh4' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('nh4')">NH4</b-button>
      <b-button :type="field === 'tan' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('tan')">TAN</b-button>
      <b-button :type="field === 'co3' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('co3')">CO3</b-button>
      <b-button :type="field === 'hco3' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('hco3')">HCO3</b-button>
      <b-button :type="field === 'alkalinity' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('alkalinity')">Alkalinity</b-button>
      <b-button :type="field === 'ca' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('ca')">Ca</b-button>
      <b-button :type="field === 'mg' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('mg')">Mg</b-button>
      <b-button :type="field === 'hard' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('hard')">Hardness</b-button>
    </div>
    <div class="my-3 btn-tabs" v-else-if="type === 'biological'">
      <b-button :type="field === 'tbc' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('tbc')">TBC</b-button>
      <b-button :type="field === 'tvc' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('tvc')">TVC</b-button>
      <b-button :type="field === 'tvctbc' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('tvctbc')">TVC/TBC</b-button>
      <b-button :type="field === 'yvc' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('yvc')">YVC</b-button>
      <b-button :type="field === 'gvc' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('gvc')">GVC</b-button>
      <b-button :type="field === 'lbc' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('lbc')">LBC</b-button>
      <b-button :type="field === 'bvc' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('bvc')">BVC</b-button>
      <b-button :type="field === 'bga' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('bga')">%BGA</b-button>
      <b-button :type="field === 'ga' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('ga')">%GA</b-button>
      <b-button :type="field === 'gga' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('gga')">%GGA</b-button>
      <b-button :type="field === 'dino' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('dino')">%DINO</b-button>
      <b-button :type="field === 'dia' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('dia')">%DIA</b-button>
      <b-button :type="field === 'pro' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('pro')">%PRO</b-button>
      <b-button :type="field === 'eug' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('eug')">%EUG</b-button>
      <b-button :type="field === 'plankton' ? 'is-danger is-light' : 'is-text'" class="mr-2" @click="handleChangeField('plankton')">Plankton</b-button>
    </div>
    <div class="my-5" v-if="!loading">
      <div class="columns is-tablet" v-if="data.length">
        <div class="column is-half-tablet" v-for="(item, key) in data" :key="key">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                {{ item.pond_name }}
              </p>
            </header>
            <section class="card-content">
              <div v-if="['color', 'weather'].includes(field)" style="height: 180px; overflow-y: auto">
                <div v-for="(value, key) in item.data" :key="key">
                  <div class="pb-2"
                       v-if="(item.data[key - 1] && value.date !== item.data[key - 1].date) || key === 0">{{ $dateFormat(value.date) }}
                  </div>
                  <div class="pb-4">
                    <b-tag rounded>{{ $timeFormat(value.date) }}</b-tag>
                    <span class="ml-2" v-if="field==='color'">{{ $getColorLabel(value.value) }}</span>
                    <span class="ml-2" v-else-if="field==='weather'">{{ $getWeatherLabel(value.value) }}</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="is-flex is-justify-content-space-between">
                  <div class="mb-3" v-if="item.data.length">
                    <h4 class="is-size-5 is-flex">{{ $number(item.data[0].value) }}</h4>
                    <p class="has-text-weight-bold has-text-grey">{{ $dateFormat(item.data[0].date) }}, {{ $timeFormat(item.data[0].date) }}</p>
                  </div>

                  <div class="is-flex is-flex-direction-column is-justify-content-flex-end mb-3" v-if="type === 'general'">
                    <b-field class="mb-1" :style="{opacity: item.show_morning ? '1' : '.5'}">
                      <p class="control">
                        <b-button size="is-small" icon-left="circle" class="h-100"
                                  @click="toggleShowChart(item, 'morning')"
                                  :style="{
                                    color: '#3998EB',
                                  }"></b-button>
                      </p>
                      <p class="control w-100">
                        <b-button size="is-small" outlined expanded @click="toggleShowChart(item, 'morning')">
                          {{ $t('wq.morning') }}
                        </b-button>
                      </p>
                    </b-field>
                    <b-field class="mb-1" :style="{opacity: item.show_afternoon ? '1' : '.5'}">
                      <p class="control">
                        <b-button size="is-small" icon-left="circle" class="h-100"
                                  @click="toggleShowChart(item, 'afternoon')"
                                  style="color: #FF9100"></b-button>
                      </p>
                      <p class="control w-100">
                        <b-button size="is-small" outlined expanded @click="toggleShowChart(item, 'afternoon')">
                          {{ $t('wq.afternoon') }}
                        </b-button>
                      </p>
                    </b-field>
                    <b-field class="mb-0" :style="{opacity: item.show_evening ? '1' : '.5'}">
                      <p class="control">
                        <b-button size="is-small" icon-left="circle" class="h-100"
                                  @click="toggleShowChart(item, 'evening')"
                                  style="color: #ee3342"></b-button>
                      </p>
                      <p class="control w-100">
                        <b-button size="is-small" outlined expanded @click="toggleShowChart(item, 'evening')">
                          {{ $t('wq.evening') }}
                        </b-button>
                      </p>
                    </b-field>
                  </div>
                </div>
                <apexchart v-if="!item.loading"
                           type="area"
                           height="350"
                           :options="item.options"
                           :series="item.series"></apexchart>
              </div>
            </section>
<!--            <footer class="card-footer">-->
<!--              <router-link class="card-footer-item has-text-weight-bold" :to="{-->
<!--                path:`/farm/${farm_id}/water-quality`,-->
<!--                query: {-->
<!--                  block_id: item.block_id,-->
<!--                  pond_id: item.pond_id,-->
<!--                  cycle_id: item.cycle_id,-->
<!--                }-->
<!--              }">View Table Data-->
<!--              </router-link>-->
<!--            </footer>-->
          </div>
        </div>
      </div>
      <div v-else class="py-4">
        <p class="has-text-centered has-text-grey">{{ $t('no') }} Data</p>
      </div>
    </div>
    <b-loading :is-full-page="false" v-model="loading" style="min-height: 350px"></b-loading>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "WaterQualityChart",
  props: {
    type: {
      type: String
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data: () => {
    return {
      data: [],
      field: 'ph',
      settings: [],
      loading: false,
    }
  },
  computed: {
    wqs() {
      return this.$store.state.setting.wqs
    },
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    ponds() {
      return this.$store.state.farm.overview.ponds
    },
    refresh_data() {
      return this.$store.state.farm.overview.refresh_data
    },
  },
  watch: {
    refresh_data(value) {
      if (value) this.getData()
    },
    type(value) {
      if (value === 'general') this.field = 'ph'
      else if (value === 'chemical') this.field = 'no2'
      else if (value === 'biological') this.field = 'tbc'
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true

      if (this.wqs) {
        this.settings = []
        let settings = await this.$store.dispatch('farm/getWqs', this.farm_id)
        if (!settings.error && settings.data && settings.data.length) this.settings = settings.data
      }

      this.data = []

      for (let i = 0; i < this.ponds.length; i++) {
        let pond = this.ponds[i]

        if (pond.selected_cycle) {
          let res = await this.$store.dispatch('farm/getWaterQualityChart', {
            farm_id: pond.farm_id,
            pond_id: pond.pond_id,
            cycle_id: pond.selected_cycle.cycle_id,
            category: this.type.toLowerCase(),
            field: this.field,
          })

          if (!res.error && res.data && res.data.length) {
            this.data.push({
              cycle_id: pond.selected_cycle.cycle_id,
              pond_id: pond.pond_id,
              block_id: pond.block_id,
              pond_name: pond.name,
              show_morning: true,
              show_afternoon: true,
              show_evening: true,
              loading: true,
              data: res.data.filter(e => e.value !== -99).map(e => {
                let time = parseInt(this.$timeFormat(e.created_at, 'H'))
                if (time < 12) time = 'morning'
                else if (time >= 12 && time < 18) time = 'afternoon'
                else time = 'evening'
                return {
                  date: e.created_at,
                  value: e.value,
                  time: time,
                }
              }).sort((a, b) => b.date > a.date ? 1 : -1),
            })
          }
        }
      }

      await this.generateChart()

      this.$nextTick(() => {
        this.loading = false
        this.$store.dispatch('farm/refreshOverview', false)
      })
    },
    generateChart() {
      let annotations = {}

      if (this.wqs) {
        let wqs = this.settings.find(e => e.name === this.field)
        if (this.field === 'oxygen') wqs = this.settings.find(e => e.name === 'oxygen_saturation')
        if (wqs) {
          annotations = {
            position: "back",
            yaxis: [
              {
                label: {
                  text: " "
                },
                y: 0,
                y2: wqs.min_value,
                fillColor: "#ec1c24"
              },
              {
                label: {
                  text: " "
                },
                y: wqs.min_value,
                y2: wqs.max_value,
                fillColor: "#c4ff0e"
              },
              {
                label: {
                  text: " "
                },
                y: wqs.max_value,
                y2: wqs.max_limit,
                fillColor: "#ec1c24"
              }
            ]
          }
        }
      }

      this.data = this.data.filter(e => e.data.length)
      for (let i = 0; i < this.data.length; i++) {
        let data = this.data[i]
        if (data.data && data.data.length) {
          let min_date = data.data.sort((a, b) => a.created_at > b.created_at ? 1 : -1)[0]
          let max_date = data.data.sort((a, b) => b.created_at > a.created_at ? 1 : -1)[0]
          if (this.type === 'general') {
            data.series = [
              {
                name: this.$t('wq.morning'),
                data: data.show_morning ? data.data.filter(e => e.time === 'morning').map(e => {
                      return {
                        x: this.$startDay(e.date).valueOf(),
                        y: e.value
                      }
                    }).sort((a, b) => a.x > b.x ? 1 : -1)
                    : []
              },
              {
                name: this.$t('wq.afternoon'),
                data: data.show_afternoon ? data.data.filter(e => e.time === 'afternoon').map(e => {
                      return {
                        x: this.$startDay(e.date).valueOf(),
                        y: e.value
                      }
                    }).sort((a, b) => a.x > b.x ? 1 : -1)
                    : []
              },
              {
                name: this.$t('wq.evening'),
                data: data.show_evening ? data.data.filter(e => e.time === 'evening').map(e => {
                      return {
                        x: this.$startDay(e.date).valueOf(),
                        y: e.value
                      }
                    }).sort((a, b) => a.x > b.x ? 1 : -1)
                    : []
              },
            ]
          } else {
            data.series = [
              {
                name: this.field,
                data: data.data.map(e => {
                  return {
                    x: this.$startDay(e.date).valueOf(),
                    y: this.$number(e.value)
                  }
                }).sort((a, b) => a.x > b.x ? 1 : -1)
              },
            ]
          }
          data.options = {
            annotations: annotations,
            chart: {
              type: 'area',
              height: 350,
              zoom: {
                enabled: true
              },
              stacked: false,
            },
            colors: ['#3998EB', '#FF9100', '#ee3342'],
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            xaxis: {
              type: 'datetime',
              min: min_date,
              max: max_date,
              labels: {
                // rotate: -15,
                rotateAlways: false,
                formatter: (val, timestamp) => {
                  return this.$dateFormat(timestamp, 'DD MMM YYYY')
                }
              }
            },
            legend: {
              show: false
            }
          }
        }

        data.loading = false
      }
    },

    handleChangeField(field) {
      this.field = field
      this.getData()
    },

    toggleShowChart(item, type) {
      item.loading = true
      if (type === 'morning') item.show_morning = !item.show_morning
      if (type === 'afternoon') item.show_afternoon = !item.show_afternoon
      if (type === 'evening') item.show_evening = !item.show_evening
      this.generateChart()
    }
  }
}
</script>
