<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
  <div>
    <div class="mb-5">

      <sg-tab type="button" class="mb-3" v-model="type" v-if="!hideAction"
              :items="[{label: $t('wq.general'), value: 'general'}, {label: $t('wq.chemical'), value: 'chemical'}, {label: $t('wq.biological'), value: 'biological'}]"/>

      <div class="b-table">
        <div class="table-wrapper has-sticky-header table-container overflow-y-auto" style="max-height: 550px" v-if="type === 'general'">
          <table class="table is-bordered">
            <thead>
            <tr v-if="dateLabel">
              <th colspan="999" class="p-0" style="border: 0">
                <p class="has-text-weight-bold has-text-dark is-size-5 mb-2">{{ dateLabel }}</p>
              </th>
            </tr>
            <tr class="header-row">
              <th rowspan="2">{{ $t('farm.pond') }}</th>
              <th rowspan="2">DOC</th>
              <th :colspan="water_quality.opts.total_general.length" v-if="hasGeneral" style="min-width: 100px">pH</th>
              <th v-if="hasGeneral" style="min-width: 100px">pH Range</th>
              <th :colspan="water_quality.opts.total_general.length" v-if="hasGeneral" style="min-width: 100px">DO</th>
              <th :colspan="water_quality.opts.total_general.length" v-if="hasGeneral">{{ $t('wq.temperature') }}</th>
              <th :colspan="water_quality.opts.total_general.length" v-if="hasGeneral" style="min-width: 100px">{{ $t('wq.turbidity') }}</th>
              <th :colspan="water_quality.opts.total_general.length" v-if="hasGeneral" style="min-width: 100px">{{ $t('wq.wh') }}</th>
              <th :colspan="water_quality.opts.total_general.length" v-if="hasGeneral" style="min-width: 100px">{{ $t('wq.salinity') }}</th>
              <th :colspan="water_quality.opts.total_general.length" v-if="hasGeneral">{{ $t('wq.oxygen') }}</th>
              <th :colspan="water_quality.opts.total_general.length" v-if="hasGeneral" style="min-width: 100px">ORP</th>
              <th :colspan="water_quality.opts.total_general.length" v-if="hasGeneral" style="width: 200px;">{{ $t('wq.color') }}</th>
              <th :colspan="water_quality.opts.total_general.length" v-if="hasGeneral" style="width: 200px;">{{ $t('wq.weather') }}</th>
              <th rowspan="2" v-if="!hideAction"></th>
            </tr>
            <tr>
              <th v-for="n in water_quality.opts.total_general.length" :key="n">
                <span>{{ $timeFormat($addMinute(water_quality.opts.total_general[(n - 1) % water_quality.opts.total_general.length].created_at, timediff)) }}</span>
              </th>
              <th v-if="water_quality.opts.total_general.length"></th>
              <th v-for="n in water_quality.opts.total_general.length * 9" :key="n + water_quality.opts.total_general.length">
                <span>{{ $timeFormat($addMinute(water_quality.opts.total_general[(n - 1) % water_quality.opts.total_general.length].created_at, timediff)) }}</span>
              </th>
            </tr>
            </thead>
            <tbody v-if="hasGeneral">
            <tr v-for="(item, key) in wqData" :key="key" v-if="item.general.find(e => e.show !== false)">
              <td>{{ item.pond_name }}</td>
              <td>{{ doc(item.pond_id, item.cycle_id) }}</td>
              <td v-for="(data, n) in item.general" :key="`ph-${n}`">
              <span :class="{'has-text-danger': isOutLimit('ph', data, item.general)}" class="editable-text" @click="editWq(data, 'ph')">
                {{ data.ph && data.ph !== -99 ? data.ph : '-' }}</span>
              </td>
              <td :class="{'has-text-danger': isOutLimitRangePh(rangePhValue(item.general))}">
                {{ rangePhValue(item.general) }}
              </td>
              <td v-for="(data, n) in item.general" :key="`do-${n}`">
              <span :class="{'has-text-danger': isOutLimit('do', data)}" class="editable-text" @click="editWq(data, 'DO')">
                {{ data.DO && data.DO !== -99 ? data.DO : '-' }}</span>
              </td>
              <td v-for="(data, n) in item.general" :key="`temperature-${n}`">
              <span :class="{'has-text-danger': isOutLimit('temperature', data)}" class="editable-text" @click="editWq(data, 'temperature')">
                {{ data.temperature && data.temperature !== -99 ? data.temperature : '-' }}</span>
              </td>
              <td v-for="(data, n) in item.general" :key="`turbidity-${n}`">
              <span :class="{'has-text-danger': isOutLimit('turbidity', data)}" class="editable-text" @click="editWq(data, 'turbidity')">
                {{ data.turbidity && data.turbidity !== -99 ? data.turbidity : '-' }}</span>
              </td>
              <td v-for="(data, n) in item.general" :key="`water_height-${n}`">
              <span :class="{'has-text-danger': isOutLimit('water_height', data)}" class="editable-text" @click="editWq(data, 'water_height')">
                {{ data.water_height && data.water_height !== -99 ? data.water_height : '-' }}</span>
              </td>
              <td v-for="(data, n) in item.general" :key="`salinity-${n}`">
              <span :class="{'has-text-danger': isOutLimit('salinity', data)}" class="editable-text" @click="editWq(data, 'salinity')">
                {{ data.salinity && data.salinity !== -99 ? data.salinity : '-' }}</span>
              </td>
              <td v-for="(data, n) in item.general" :key="`oxygen-${n}`">
              <span :class="{'has-text-danger': isOutLimit('oxygen', data)}" class="editable-text" @click="editWq(data, 'oxygen')">
                {{ data.oxygen && data.oxygen !== -99 ? data.oxygen : '-' }}</span>
              </td>
              <td v-for="(data, n) in item.general" :key="`orp-${n}`">
              <span :class="{'has-text-danger': isOutLimit('orp', data)}" class="editable-text" @click="editWq(data, 'orp')">
                {{ data.orp && data.orp !== -99 ? data.orp : '-' }}</span>
              </td>
              <td v-for="(data, n) in item.general" :key="`color-${n}`">
              <span :class="{'has-text-danger': isOutLimit('color', data)}" class="editable-text" @click="editWq(data, 'color')">
                {{ data.color && data.color !== -99 ? $getColorLabel(data.color) : '-' }}</span>
              </td>
              <td v-for="(data, n) in item.general" :key="`weather-${n}`">
              <span :class="{'has-text-danger': isOutLimit('weather', data)}" class="editable-text" @click="editWq(data, 'weather')">
                {{ data.weather && data.weather !== -99 ? $getWeatherLabel(data.weather) : '-' }}</span>
              </td>
              <td v-if="!hideAction">
                <b-button type="is-danger is-light" @click="openDeleteModal('general', item)" icon-left="trash-can-outline"></b-button>
              </td>
            </tr>
            </tbody>

            <tbody v-else>
            <tr>
              <td colspan="6">
                <p class="has-text-centered">{{ $t('no') }} Data</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table-wrapper has-sticky-header table-container overflow-y-auto" style="max-height: 550px" v-if="type === 'chemical'">
          <table class="table is-bordered">
            <thead>
            <tr v-if="dateLabel">
              <th colspan="999" class="p-0" style="border: 0">
                <p class="has-text-weight-bold has-text-dark is-size-5 mb-2">{{ dateLabel }}</p>
              </th>
            </tr>
            <tr class="header-row">
              <th>{{ $t('farm.pond') }}</th>
              <template v-if="hasChemical">
              <th>DOC</th>
              <th>TOM</th>
              <th>Alkalinity</th>
              <th>PO4</th>
              <th>NO3</th>
              <th>NO2</th>
              <th>NH4</th>
              <th>NH3</th>
              <th>TAN</th>
              <th>CO3</th>
              <th>HCO3</th>
              <th>Ca</th>
              <th>Mg</th>
              <th>Hardness</th>
              </template>
              <th></th>
            </tr>
            </thead>
            <template v-if="hasChemical">
              <tbody v-for="(wq, key) in wqData" :key="key">
              <tr v-for="(item, key) in wq.chemical" :key="key">
                <td>{{ wq.pond_name }}</td>
                <td>{{ doc(wq.pond_id, item.cycle_id) }}</td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('tom', item)}" class="editable-text"
                    @click="editWq(item, 'tom')">
                {{ item && item.tom !== -99 ? item.tom : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('alkalinity', item)}" class="editable-text"
                    @click="editWq(item, 'alkalinity')">
                {{ item && item.alkalinity !== -99 ? item.alkalinity : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('po4', item)}" class="editable-text"
                    @click="editWq(item, 'po4')">
                {{ item && item.po4 !== -99 ? item.po4 : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('no3', item)}" class="editable-text"
                    @click="editWq(item, 'no3')">
                {{ item && item.no3 !== -99 ? item.no3 : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('no2', item)}" class="editable-text"
                    @click="editWq(item, 'no2')">
                {{ item && item.no2 !== -99 ? item.no2 : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('nh4', item)}" class="editable-text"
                    @click="editWq(item, 'nh4')">
                {{ item && item.nh4 !== -99 ? item.nh4 : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('nh3', item)}" class="editable-text"
                    @click="editWq(item, 'nh3')">
                {{ item && item.nh3 !== -99 ? item.nh3 : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('tan', item)}" class="editable-text"
                    @click="editWq(item, 'tan')">
                {{ item && item.tan !== -99 ? item.tan : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('co3', item)}" class="editable-text"
                    @click="editWq(item, 'co3')">
                {{ item && item.co3 !== -99 ? item.co3 : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('hco3', item)}" class="editable-text"
                    @click="editWq(item, 'hco3')">
                {{ item && item.hco3 !== -99 ? item.hco3 : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('ca', item)}" class="editable-text"
                    @click="editWq(item, 'ca')">
                {{ item && item.ca !== -99 ? item.ca : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('mg', item)}" class="editable-text"
                    @click="editWq(item, 'mg')">
                {{ item && item.mg !== -99 ? item.mg : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('hard', item)}" class="editable-text"
                    @click="editWq(item, 'hard')">
                {{ item && item.hard !== -99 ? item.hard : '-' }}</span>
                </td>
                <td>
                  <b-button type="is-danger is-light" @click="deleteWq('chemical', item)"
                            icon-left="trash-can-outline"></b-button>
                </td>
              </tr>
              </tbody>
            </template>
            <tbody v-else>
            <tr>
              <td colspan="6">
                <p class="has-text-centered">{{ $t('no') }} Data</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table-wrapper has-sticky-header table-container overflow-y-auto" style="max-height: 550px" v-if="type === 'biological'">
          <table class="table is-bordered">
            <thead>
            <tr v-if="dateLabel">
              <th colspan="999" class="p-0" style="border: 0">
                <p class="has-text-weight-bold has-text-dark is-size-5 mb-2">{{ dateLabel }}</p>
              </th>
            </tr>
            <tr class="header-row">
              <th>{{ $t('farm.pond') }}</th>
              <th>DOC</th>
              <template v-if="hasBacteria">
              <th>TBC</th>
              <th>TVC</th>
              <th>TVC/TBC(%)</th>
              <th>YVC</th>
              <th>GVC</th>
              <th>BVC</th>
              <th>LBC</th>
              <th>%BGA</th>
              <th>%GA</th>
              <th>%GGA</th>
              <th>%DINO</th>
              <th>%DIA</th>
              <th>%PRO</th>
              <th>%EUG</th>
              <th>PLANKTON</th>
              </template>
              <th></th>
            </tr>
            </thead>
            <template v-if="hasBacteria">
              <tbody v-if="hasBacteria" v-for="(wq, key) in wqData" :key="key">
              <tr v-for="(item, key) in wq.bacteria" :key="key">
                <td>{{ wq.pond_name }}</td>
                <td>{{ doc(wq.pond_id, item.cycle_id) }}</td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('tbc', item)}" class="editable-text"
                    @click="editWq(item, 'tbc')">
                {{ item && item.tbc !== -99 ? $exponential(item.tbc) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('tvc', item)}">
                {{ item && item.tvc !== -99 ? $exponential(item.tvc) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('tvctbc', item)}">
                {{ item && item.tvctbc !== -99 ? $number(item.tvctbc) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('yvc', item)}" class="editable-text"
                    @click="editWq(item, 'yvc')">
                {{ item && item.yvc !== -99 ? $exponential(item.yvc) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('gvc', item)}" class="editable-text"
                    @click="editWq(item, 'gvc')">
                {{ item && item.gvc !== -99 ? $exponential(item.gvc) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('bvc', item)}" class="editable-text"
                    @click="editWq(item, 'bvc')">
                {{ item && item.bvc !== -99 ? $exponential(item.bvc) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('lbc', item)}" class="editable-text"
                    @click="editWq(item, 'lbc')">
                {{ item && item.lbc !== -99 ? $exponential(item.lbc) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('bga', item)}" class="editable-text"
                    @click="editWq(item, 'bga')">
                {{ item && item.bga !== -99 ? $number(item.bga) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('ga', item)}" class="editable-text"
                    @click="editWq(item, 'ga')">
                {{ item && item.ga !== -99 ? $number(item.ga) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('gga', item)}" class="editable-text"
                    @click="editWq(item, 'gga')">
                {{ item && item.gga !== -99 ? $number(item.gga) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('dino', item)}" class="editable-text"
                    @click="editWq(item, 'dino')">
                {{ item && item.dino !== -99 ? $number(item.dino) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('dia', item)}" class="editable-text"
                    @click="editWq(item, 'dia')">
                {{ item && item.dia !== -99 ? $number(item.dia) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('pro', item)}" class="editable-text"
                    @click="editWq(item, 'pro')">
                {{ item && item.pro !== -99 ? $number(item.pro) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('eug', item)}" class="editable-text"
                    @click="editWq(item, 'eug')">
                {{ item && item.eug !== -99 ? $number(item.eug) : '-' }}</span>
                </td>
                <td>
              <span :class="{'has-text-danger': isOutLimit('plankton', item)}" class="editable-text"
                    @click="editWq(item, 'plankton')">
                {{ item && item.plankton !== -99 ? $exponential(item.plankton) : '-' }}
              </span>
                </td>
                <td>
                  <b-button type="is-danger is-light" @click="deleteWq('biological', item)"
                            icon-left="trash-can-outline"></b-button>
                </td>
              </tr>
              </tbody>
            </template>

            <tbody v-else>
            <tr>
              <td colspan="6">
                <p class="has-text-centered">{{ $t('no') }} Data</p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <water-quality-overview :type="type" v-if="!hideChart"/>

    <!--  Modal Edit  -->

    <b-modal
        class="modal-center"
        v-model="editModal"
        has-modal-card
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <div class="card">
          <div class="card-content">
            <form @submit.prevent="handleUpdate">
              <b-field>
                <div v-if="['color', 'weather'].includes(form.field)">
                  <b-select type="is-secondary is-light"
                            ref="edit-input"
                            :placeholder="$t('select') + ' ' +  $t('wq.color')" expanded
                            style="min-width: 140px"
                            v-model="form.data[form.field]" v-if="form.field === 'color'">
                    <option :value="item" v-for="item in $getColor" :key="`color-${item.value}`">{{ item[$labelLang] }}</option>
                  </b-select>
                  <b-select type="is-secondary is-light"
                            ref="edit-input"
                            :placeholder="$t('select') + ' ' +  $t('wq.weather')" expanded
                            style="min-width: 140px"
                            v-model="form.data[form.field]" v-if="form.field === 'weather'">
                    <option :value="item" v-for="item in $getWeather" :key="`weather-${item.value}`">{{ item[$labelLang] }}</option>
                  </b-select>
                </div>
                <b-input type="number" v-model.number="form.data[form.field]" custom-class="has-text-centered" ref="edit-input" step="any" v-else/>
              </b-field>
              <div class="is-flex is-justify-content-flex-end">
                <b-button type="is-secondary is-light" @click="props.close" size="is-small">{{ $t('cancel') }}</b-button>
                <b-button type="is-success" class="ml-2" native-type="submit" size="is-small">{{ $t('save') }}</b-button>
              </div>
            </form>
          </div>
        </div>
      </template>
    </b-modal>

    <!--  Modal Delete  -->

    <b-modal
        class="modal-center"
        v-model="deleteModal"
        has-modal-card
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <div class="card" style="width: 500px;">
          <div class="card-content">
            <h2 class="is-size-6 has-text-danger has-text-weight-bold mb-2">
              {{ $t('delete') }} {{ $t('wq.wq') }}
            </h2>
            <ValidationObserver ref="delete-form">
              <form @submit.prevent="handleDelete">
                <ValidationProvider :name="$t('farm.pond')" rules="required" v-slot="{ errors }">
                  <b-field :label="`${$t('select')} ${$t('farm.pond')}`" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-autocomplete
                        append-to-body
                        open-on-focus
                        keep-first
                        :data="filteredDeletePonds"
                        :placeholder="$t('search') + ' ' + $t('farm.pond')"
                        field="pond_name"
                        v-model="deleteForm.search_pond"
                        @select="value => deleteForm.ponds = value"
                    />
                  </b-field>
                </ValidationProvider>
                <ValidationProvider :name="$t('time')" rules="required" v-slot="{ errors }" v-if="type ==='general' ">
                  <b-field :label="`${$t('select')} ${$t('time')}`" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-checkbox v-model="deleteForm.times" v-for="(item, key) in deleteTimes" :native-value="item" :key="key">
                      {{ $timeFormat(item) }}
                    </b-checkbox>
                  </b-field>
                </ValidationProvider>
                <div class="is-flex is-justify-content-flex-end">
                  <b-button type="is-secondary is-light" @click="props.close" size="is-small">{{ $t('cancel') }}</b-button>
                  <b-button type="is-danger" class="ml-2" native-type="submit" size="is-small">{{ $t('delete') }}</b-button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import WaterQualityOverview from "@/components/Farm/Overview/WaterQuality/WaterQualityOverview";
import SgTab from "@/components/Sg/SgTab";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "WaterQualityAnalysis",
  props: {
    hideChart: Boolean,
    hideAction: Boolean,
    defaultType: {
      type: String,
      default: 'general',
    },
    timediff: Number,
    dateLabel: String,
  },
  components: {SgTab, WaterQualityOverview},
  data: () => {
    return {
      type: 'general',
      wqs: [],
      form: {
        data: {},
        field: null,
      },
      deleteForm: {
        ponds: [],
        times: [],
        search_pond: '',
      },
      editModal: false,
      deleteModal: false,
      deletePonds: [],
      deleteTimes: [],
      yesterdayData: [],
    }
  },
  computed: {
    ...mapState('farm', [
      'analysis'
    ]),

    ...mapGetters('farm', [
      'selectedFarm',
    ]),

    water_quality() {
      return this.analysis.water_quality
    },

    selectedPonds: {
      get() {
        return this.$store.state.farm.overview.ponds
      },
      set() {
      }
    },

    pond_ids() {
      return this.selectedPonds ? this.selectedPonds.map(e => e.pond_id) : []
    },

    wqData() {
      if (this.water_quality && this.water_quality.data) {
        if (this.pond_ids) {
          return this.water_quality.data.filter(e => this.pond_ids.includes(e.pond_id))
        }
      }
      return []
    },

    hasGeneral () {
      return this.wqData.find(e=>e.general.length)
    },

    hasChemical () {
      return this.wqData.find(e=>e.chemical.length)
    },

    hasBacteria () {
      return this.wqData.find(e=>e.bacteria.length)
    },

    filteredDeletePonds() {
      return this.deletePonds.filter(option => {
        return (
            option.pond_name
                .toString()
                .toLowerCase()
                .indexOf(this.deleteForm.search_pond.toLowerCase()) >= 0
        )
      })
    },
  },
  watch: {
    'analysis.date'() {
      this.getData()
    }
  },
  async created() {
    this.type = this.defaultType

    await this.$store.dispatch('farm/getWqs', this.selectedFarm.farm_id).then(res => {
      if (!res.error && res.data && res.data.length) {
        this.wqs = res.data
      }
    })

    let data = this.water_quality.data
    if (!data || !data.length) {
      this.getData()
    }
  },
  methods: {
    ...mapActions('farm', [
      'getWaterQualityAnalysis'
    ]),

    isOutLimit(type, data, allData = null) {
      let find = this.wqs.find(e => e.name.toLowerCase() === type)

      if (type === 'ph') {
        let hour = this.$moment(data.created_at).hour()
        let morning_morning = this.wqs.find(e => e.name === 'ph_morning_to_morning')
        let morning_evening = this.wqs.find(e => e.name === 'ph_morning_to_evening')
        let evening_evening = this.wqs.find(e => e.name === 'ph_evening_to_evening')

        if (hour < 12) {
          if (morning_morning && this.yesterdayData.length) {
            let findData = cloneDeep(this.yesterdayData).find(e => e.pond_id === data.pond_id)
            if (findData) {
              findData = findData.general.sort((a, b) => b.created_at > a.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() < 12)
              if (findData) {
                let diff = data.ph - findData.ph
                return diff > morning_morning.max_value || diff < -morning_morning.max_value
              }
            }
          }
        } else {
          if (morning_evening) {
            let findData = this._.cloneDeep(allData).sort((a, b) => b.created_at > a.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() < 12)
            if (findData) {
              let diff = data.ph - findData.ph
              diff = diff > morning_evening.max_value || diff < -morning_evening.max_value
              if (diff) return true
            }
          }

          if (evening_evening && this.yesterdayData.length) {
            let findData = cloneDeep(this.yesterdayData).find(e => e.pond_id === data.pond_id)
            if (findData) {
              findData = findData.general.sort((a, b) => a.created_at > b.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() >= 12)
              if (findData) {
                let diff = data.ph - findData.ph
                return diff > evening_evening.max_value || diff < -evening_evening.max_value
              }
            }
          }
        }
      }

      if (find && data && (data[type] !== -99 || data.type !== undefined)) {
        if (typeof find.min_value === 'number' && typeof find.max_value === 'number')
          return !(data[type] >= find.min_value && data[type] <= find.max_value)
        return data[type] < find.min_value || data[type] > find.max_value
      }

      return false
    },

    rangePhValue(data) {
      let diff = '-'

      let hour = this.$moment(data.created_at).hour()
      // let morning_morning = this.wqs.find(e => e.name === 'ph_morning_to_morning')
      let morning_evening = this.wqs.find(e => e.name === 'ph_morning_to_evening')
      // let evening_evening = this.wqs.find(e => e.name === 'ph_evening_to_evening')

      // if (hour < 12) {
        // if (morning_morning && this.yesterdayData.length) {
        //   let findData = cloneDeep(this.yesterdayData).find(e => e.pond_id === data.pond_id)
        //   if (findData) {
        //     findData = findData.general.sort((a, b) => b.created_at > a.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() < 12)
        //     if (findData) {
        //       let diff = data.ph - findData.ph
        //       return diff > morning_morning.max_value || diff < -morning_morning.max_value
        //     }
        //   }
        // }
      // } else {
      //   if (morning_evening) {
          let morningData = this._.cloneDeep(data).sort((a, b) => b.created_at > a.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() < 12 && e.ph && e.ph !== -99)
          let eveningData = this._.cloneDeep(data).sort((a, b) => a.created_at > b.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() >= 12 && e.ph && e.ph !== -99)
          if (morningData && eveningData) {
            diff = Math.round((eveningData.ph - morningData.ph) * 10) / 10
          }
        // }

        // if (evening_evening && this.yesterdayData.length) {
        //   let findData = cloneDeep(this.yesterdayData).find(e => e.pond_id === data.pond_id)
        //   if (findData) {
        //     findData = findData.general.sort((a, b) => a.created_at > b.created_at ? 1 : -1).find(e => this.$moment(e.created_at).hour() >= 12)
        //     if (findData) {
        //       let diff = data.ph - findData.ph
        //       return diff > evening_evening.max_value || diff < -evening_evening.max_value
        //     }
        //   }
        // }
      // }
      return diff
    },

    isOutLimitRangePh (value) {
      let morning_evening = this.wqs.find(e => e.name === 'ph_morning_to_evening')

      if (morning_evening) {
        return value > morning_evening.max_value || value < -morning_evening.max_value
      }

      return false
    },

    async getData() {
      this.$loading()

      let morning_morning = this.wqs.find(e => e.name === 'ph_morning_to_morning')
      let evening_evening = this.wqs.find(e => e.name === 'ph_evening_to_evening')

      if (morning_morning || evening_evening) {
        this.yesterdayData = []

        let res = await this.getWaterQualityAnalysis({
          date: this.$moment(this.analysis.date).add({days: -1}).toDate(),
          updateState: false,
          pond_ids: this.pond_ids,
        })

        if (res && res.data) this.yesterdayData = res.data
      }

      await this.getWaterQualityAnalysis({
        pond_ids: this.pond_ids,
      })

      this.$emit('loaded')

      this.$loading(false)
    },

    doc(pond_id, cycle_id) {
      if (this.selectedFarm.ponds) {
        let find = this.selectedFarm.ponds.find(e => e.pond_id === pond_id)

        if (find && find.stock) {
          let stock = find.stock.find(e => e.cycle_id === cycle_id)

          if (stock) return this.$getDOC(stock.stock_date, this.analysis.date)
        }
      }

      return '-'
    },

    editWq(item, field) {
      delete item.block_name
      delete item.pond_name
      delete item.user_id
      delete item.stock_id
      this.form = {
        data: this._.cloneDeep(item),
        field
      }

      if (this.form.data[field] === -99) this.form.data[field] = null
      this.editModal = true

      if (field === 'weather') this.form.data.weather = this.$getWeatherById(this.form.data.weather)
      else if (field === 'color') this.form.data.color = this.$getColorById(this.form.data.color)

      this.$nextTick(() => {
        this.$refs["edit-input"].focus()
      })
    },

    async handleUpdate() {
      let item = this.form.data

      this.$loading()

      let isFilled = false

      let data = this._.cloneDeep(item)
      delete data.general_id
      delete data.chemical_id
      delete data.biological_id
      delete data.farm_id
      delete data.block_id
      delete data.pond_id
      delete data.cycle_id
      delete data.created_at
      delete data.updated_at

      for (let k in data) {
        if (data[k] && data[k] !== -99) isFilled = true
      }

      if (item.general_id) {
        if (isFilled) await this.$store.dispatch('farm/updateWaterQualityGeneral', {
          general_id: item.general_id,
          farm_id: item.farm_id,
          block_id: item.block_id,
          pond_id: item.pond_id,
          cycle_id: item.cycle_id,
          color: item.color ? item.color.value : -99,
          weather: item.weather ? item.weather.value : -99,
          ph: item.ph ? item.ph : -99,
          do: item.DO ? item.DO : -99,
          salinity: item.salinity ? item.salinity : -99,
          temperature: item.temperature ? item.temperature : -99,
          turbidity: item.turbidity ? item.turbidity : -99,
          water_height: item.water_height ? item.water_height : -99,
          oxygen: item.oxygen ? item.oxygen : -99,
          orp: item.orp ? item.orp : -99,
          created_at: item.created_at,
        })
        else await this.$store.dispatch('farm/deleteWaterQualityGeneral', item.general_id)
      } else if (item.chemical_id) {
        if (isFilled) await this.$store.dispatch('farm/updateWaterQualityChemical', {
          chemical_id: item.chemical_id,
          farm_id: item.farm_id,
          block_id: item.block_id,
          pond_id: item.pond_id,
          cycle_id: item.cycle_id,
          alkalinity: item.alkalinity ? item.alkalinity : -99,
          nh4: item.nh4 ? item.nh4 : -99,
          no2: item.no2 ? item.no2 : -99,
          no3: item.no3 ? item.no3 : -99,
          po4: item.po4 ? item.po4 : -99,
          tom: item.tom ? item.tom : -99,
          tan: item.tan ? item.tan : -99,
          nh3: item.nh3 ? item.nh3 : -99,
          co3: item.co3 ? item.co3 : -99,
          hco3: item.hco3 ? item.hco3 : -99,
          ca: item.ca ? item.ca : -99,
          mg: item.mg ? item.mg : -99,
          hard: item.hard ? item.hard : -99,
          created_at: item.created_at,
        })
        else await this.$store.dispatch('farm/deleteWaterQualityChemical', item.chemical_id)
      } else if (item.biological_id) {
        if (isFilled) await this.$store.dispatch('farm/updateWaterQualityBiological', {
          biological_id: item.biological_id,
          farm_id: item.farm_id,
          block_id: item.block_id,
          pond_id: item.pond_id,
          cycle_id: item.cycle_id,
          tbc: item.tbc ? item.tbc : -99,
          yvc: item.yvc ? item.yvc : -99,
          gvc: item.gvc ? item.gvc : -99,
          lbc: item.lbc ? item.lbc : -99,
          bvc: item.bvc ? item.bvc : -99,
          bga: item.bga ? item.bga : -99,
          ga: item.ga ? item.ga : -99,
          gga: item.gga ? item.gga : -99,
          dino: item.dino ? item.dino : -99,
          dia: item.dia ? item.dia : -99,
          pro: item.pro ? item.pro : -99,
          eug: item.eug ? item.eug : -99,
          plankton: item.plankton ? item.plankton : -99,
          created_at: item.created_at,
        })
        else await this.$store.dispatch('farm/deleteWaterQualityBiological', item.biological_id)
      }
      this.$loading(false)
      this.editModal = false
      this.form.data = {}
      this.form.field = null

      await this.getData()
    },

    openDeleteModal(type, item) {
      this.deleteModal = true

      this.deleteForm.ponds = item
      this.deleteForm.search_pond = item.pond_name
      this.deletePonds = this.wqData.reduce((a, b) => {
        let find = a.find(e => e.pond_id === b.pond_id)
        if (!find) {
          if (type === 'general' && b.general.length && b.general.find(e => e.general_id)) a.push(b)
          else if (type !== 'general' && b[type]) a.push(b)
        }
        return a
      }, [])
      this.deleteTimes = this.water_quality.opts.total_general.map(e => e.created_at)
    },

    handleDelete() {
      this.$refs['delete-form'].validate().then(async success => {
        if (success) {
          this.$loading()

          let item = this.deleteForm.ponds.general.filter(e => this.deleteForm.times.includes(e.created_at))

          for (let i = 0; i < item.length; i++) {
            await this.$store.dispatch('farm/deleteWaterQualityGeneral', item[i].general_id)
          }

          await this.getData()
          this.deleteModal = false
          this.$loading(false)
        }
      })
    },

    deleteWq(type, item) {
      this.$delete(async () => {
        this.$loading()
        if (type === 'general') await this.$store.dispatch('farm/deleteWaterQualityGeneral', item.general_id)
        else if (type === 'chemical') await this.$store.dispatch('farm/deleteWaterQualityChemical', item.chemical_id)
        else if (type === 'biological') await this.$store.dispatch('farm/deleteWaterQualityBiological', item.biological_id)
        await this.getData()
        this.$loading(false)
      })
    },
  }
}
</script>

<style scoped lang="scss">
.b-table {
  th {
    white-space: nowrap;
  }
}
</style>
